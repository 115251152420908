.footer {
  text-align: center;
  color: #a1a3a6;
  padding: 20px;


  a {
    color: #a1a3a6;

    &:hover {
      color: #ffffff;
    }
  }

}
