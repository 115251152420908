.navigation {
  .ui.menu .item > i.icon {
    margin: 0;
  }

  .ui.secondary.inverted.menu .navigation__youtube-link.item:hover {
    color: #ff0000 !important;
  }

  .ui.secondary.inverted.menu .navigation__facebook-link.item:hover {
    color: #4267B2 !important;
  }

  .ui.secondary.inverted.menu .navigation__instagram-link.item:hover {
    color: #C13584 !important;
  }

  &__burger-menu {
    overflow: hidden;
    transition: height ease-in-out .5s;
    min-height: 0 !important;
    width: unset !important;

    &--open {
      height: 160px;
    }

    &--closed {
      height: 0;
    }
  }

}
