.courses {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;

  &__header {
    text-align: center;
    margin-top: 3rem !important;
  }

}
