.about {
  &__container {
    padding: 0 2rem;
  }

  &__flex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
    margin-bottom: 3rem;
    margin-top: 2rem;
  }

  &__photo {
    transition: transform ease-in-out .3s;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__text {
    font-size: 1.1rem;
  }
}
