.login {

  &__container {
    text-align: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
    align-items: center;
    row-gap: 0.25rem;
  }
}
